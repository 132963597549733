<template>
  <fragment>
    <div class="flex flex-col items-center justify-center">
      <div class="w-full md:w-1/2 xl:w-2/5 space-y-4">
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Phone number
          </div>
          <div class="flex justify-between h-9">
            <div class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer">
              <font-awesome-icon icon="fa-solid fa-user" class=" text-white" />
            </div>
            <input autofocus type="number" v-model="toPay.phone"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary" />
            <div class="bg-primary rounded-r-lg flex w-11 items-center justify-center text-center cursor-pointer" @click="openContact">
              <font-awesome-icon icon="fa-solid fa-address-book" class="text-white" />
            </div>
          </div>
        </div>
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Amount
          </div>
          <div class="flex justify-between h-9">
            <div
              class="flex bg-primary rounded-l-lg w-9 px-4 text-base font-bold items-center justify-center text-center text-white">
              {{ $store.state.currency.kode_domestik ?? "Rp" }}
            </div>
            <input type="number" v-model="toPay.amount" placeholder="0"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary" />
          </div>
        </div>
        <div id="group">
          <div class="text-sm font-semibold text-black whitespace-nowrap">
            Note
            <span class="font-medium text-xs text-black2">(optional) </span>
          </div>
          <div class="flex justify-between h-9">
            <div class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer">
              <img :src="icNote" class="w-4" alt="lihat" />
            </div>
            <input type="text" v-model="toPay.note"
              class="w-full outline-none px-5 text-xl font-semibold text-black border-b-4 border-primary" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-5 justify-end w-full mt-20">
      <div @click="inquiry"
        class="bg-primary rounded-10 text-base text-white font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg">
        Next
      </div>
    </div>
    <Contact v-if="showContact" type="cashless" ref="contact" @closeContact="closeContact" @addInput="addInput" />
  </fragment>
</template>

<script>
import { NumberFormat } from "@/utils";
import Contact from "@/components/layout/Contact.vue"

export default {
  name: "ToTripwePayInput",
  components: { Contact },
  data: () => ({
    icUser: require("@/assets/icons/ic-user.svg"),
    icArrow: require("@/assets/icons/ic-arrow-outline.svg"),
    icNote: require("@/assets/icons/ic-note.svg"),
    toPay: {
      tokenInquiry: '',
      tokenPayment: '',
      phone: '',
      amount: '',
      note: '',
    },
    showContact: false
  }),
  methods: {
    showPosition(pos) {
      const crd = pos.coords;
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition)
      } else {
        console.log("Geolocation is not supported")
      }
    },
    openContact() {
      this.showContact = true
    },
    closeContact(){
      this.showContact = false
    },
    addInput(val){
      this.toPay.phone = val
    },
    numberFormat: num => NumberFormat(num),
    next() {
      this.$router.push({
        name: 'ToTripwePayFinal',
        params: {
          user: this.toPay.phone,
          data: this.toPay
        }
      });
    },
    validasiInquiry(){
      if(this.toPay.phone == null || this.toPay.phone == ''){
        this.$toasted.global.error("Phone number cannot be empty");
        return false;
      }
      if(this.toPay.amount == null || this.toPay.amount == '' || this.toPay.amount < 0){
        this.$toasted.global.error("Minimum transaction is 1");
        return false;
      }
      return true;
    },
    async inquiry() {
      var valid = this.validasiInquiry();
      if(!valid){
        return;
      }
      this.$store.commit('setOverlayLoading', true);
      try {
        const res = await this.$http.post(`${this.$apiTripweWeb}/cashless-to-cashless/inquiry`, {
          "uuid": this.$uuidv4(),
          "phone_number": this.toPay.phone,
          "amount": this.toPay.amount,
          "remark": this.toPay.note
        });
        if (res.data.status != "200") {
          throw res.data.message;
        }

        this.toPay.dataInquiry = res.data.data;
        this.next();
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit('setOverlayLoading', false);
      }
    },
    randomStr(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  },
  created() {
    var now = this.$moment();
    this.toPay.tokenInquiry = "TWCIQ" + now.format("YYYYMMDDHHmmss") + this.randomStr(1, 9999);
    this.toPay.tokenPayment = "TWCIP" + now.format("YYYYMMDDHHmmss") + this.randomStr(1, 9999);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>